import { createColumnHelper } from '@tanstack/vue-table'
import Expander from '../Expander'

const columnHelper = createColumnHelper<any>()
export const createExpanderColumn = () =>
  columnHelper.display({
    id: 'expander',
    header: () => null,
    cell: ({ row }) => {
      return row.getCanExpand() ? (
        <Expander
          isExpanded={row.getIsExpanded()}
          onToggle={row.getToggleExpandedHandler()}
        />
      ) : null
    },
    size: 16,
    minSize: 16,
    maxSize: 16,
    enableResizing: false,
    meta: {
      fixed: 'left',
    },
  })
